





















































































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import List from "@/components/ShareComponent/PullDownUp.vue"
import FooterBottom from "@/components/ShareComponent/ListBottom.vue"
import ScrollViewX from "@/components/ShareComponent/ScrollViewX.vue"
import Stroage from "@/util/Storage"
import { UserType } from "@/Api/Basics/InterfaceType"
import { AllHelpUpData } from "@/Api/Help/interfaceType"
import { HelpTypeT } from "@/Type/index"
import { HelpStore } from "@/store/modules/Help/Help"
import { UserStore } from "@/store/modules/User"
import { IndexStore } from "@/store/modules/Index"
import { HelpTypeList,AllHelp as AllUpHelp } from "@/Api/Help/index"
import { Vue,Component, Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface AllHelpType<T=string>{
    loadingShow:boolean
    userId:T|number;
    token:T;
    UserInfo:UserType
    RightIcon:T;
    WriteIcon:T;
    NavList:Array<{ title:T;path:T; }>
    TypeList:Array<HelpTypeT>
    TypeIndex:number;
    UpData:AllHelpUpData
    StopUp:boolean;
    PullDown:boolean;
    UpDown:boolean
    List:Array<any>
    ScrollNum:number;

    initData():void;
    handleToPath(data:any):void;
    handleToPathSuc(data:any):void;
    handleChangeType(data:HelpTypeT,index:number):void;
    handleUpNavData():void;
    handlePullDown(data?:any):void;
    handleUpDown(data?:any):void;
    handleUpData(bool?:boolean):void;
    handleError(data:string):void;
}

@Component({ name:"AllHelp",components:{ ScrollViewX,List,FooterBottom,HeadTop } })
export default class AllHelp extends ZoomPage implements AllHelpType{
   loadingShow = true
   userId:string|number = ""
   token = ""
   RightIcon = require("@/assets/icon/Basics/Right.png");
   WriteIcon = require("$icon/Help/Write.png")
   NavList = [
       {
           title:"我的发布",
           path:"/myHelp"
       },{
           title:"我的接单",
           path:"/takeHelp"
       },
   ]
   TypeList:Array<HelpTypeT> = [ { name:"全部" } ]
   TypeIndex = 0;
   UserInfo:UserType = {}
   UpData:AllHelpUpData = {
       pageNo:1,
       pageSize:15
   }
   StopUp = false
   PullDown = false
   UpDown = false
   List:Array<any> = []
   ScrollNum = 0

   mounted() {
       this.loadingShow = false
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
        try{
            this.UserInfo = JSON.parse( Stroage.GetData_ && Stroage.GetData_("UserData") );
        }catch(cat){
            new Error(cat)
        }
       this.initData()
   }

   initData(){
       this.UpData = HelpStore.GetHelpAllUpData
       if( HelpStore.GetHelpITL.length ){
           this.TypeList = this.TypeList.concat(HelpStore.GetHelpITL)
       }else{
           delete this.UpData.typeName
           this.handleUpNavData()
       }
       if( HelpStore.GetHelpAllList.length ){
           this.loadingShow = false
           this.UpDown = false
           this.List = HelpStore.GetHelpAllList
           let time = setTimeout(()=>{
               this.ScrollNum = HelpStore.GetHelpScroll.AllHelpScroll
               clearTimeout( time )
           },100)
           if( this.UpData.typeName ){
               if( this.TypeList.length ){
                   let Index = this.TypeList.findIndex( a => a.name == this.UpData.typeName )
                   if( Index > 0 ){
                       this.TypeIndex = Index
                   }
               }
           }
       }else{
           this.handleUpData()
       }
   }

   handleToPath(data:any){
       if( data.path === "/issueHelp" ){
           UserStore.SetSelAddress({})
       }
       this.$router.push({
           path:data.path,
       })
   }

   handleToPathSuc(data:any){
       let ScrollData = HelpStore.GetHelpScroll
       let Num = IndexStore.GetScrollNum
       ScrollData.AllHelpScroll = Num
       HelpStore.SetHelpScroll( ScrollData )
       this.$router.push({
           path:"/allhelpSuc",
           query:{
               id:data.id // "266"//
           }
       })
   }

   handleChangeType(data:HelpTypeT,index:number){
       this.UpDown = true
       this.StopUp = false
       this.UpData.pageNo = 1
       if( index ){
           if( this.TypeIndex != index ){
               this.TypeIndex = index
               this.UpData.typeName = data.name as string
               this.handleUpData(true)
           }
       }else{
           if( this.TypeIndex != index ){
               this.TypeIndex = 0
               delete this.UpData.typeName
               this.handleUpData(true)
           }
       }
   }

    handleUpNavData(){
        this.loadingShow = true
        HelpTypeList({
            userId:this.userId,
            token:this.token
        },{school:this.UserInfo.school as string}).then(res=>{
            console.log( res )
            this.loadingShow = false
            if( res.message.code === "200" ){
                this.TypeList = this.TypeList.concat(res.data)
                HelpStore.SetHelpITL( res.data )
            }else{
                this.handleError( res.message.msg )
            }
        })
    }

    handlePullDown(data?:any){
        this.PullDown = false
        this.StopUp = false
        this.UpData.pageNo = 1
        AllUpHelp({
            userId:this.userId,
            token:this.token
        },this.UpData).then(res=>{
            this.PullDown = true
            if( res.message.code === "200" ){
               if( res.data.length < this.UpData.pageSize ){
                   this.StopUp = true
               }
               this.List = res.data
            }else{
                 this.handleError( res.message.msg )
            }
        })
    }

    handleUpDown(data?:any){
        if( this.StopUp )return;
        this.UpData.pageNo += 1
        this.UpDown = true
        this.handleUpData()
    }

    handleUpData(bool?:boolean){
        AllUpHelp({
            userId:this.userId,
            token:this.token
        },this.UpData).then(res=>{
            console.log( res )
            this.loadingShow = false
            this.UpDown = false
            if( res.message.code === "200" ){
               if( res.data.length < this.UpData.pageSize ){
                   this.StopUp = true
               }
               if( bool ){
                   this.List = res.data
               }else{
                   this.List = this.List.concat( res.data )
               }
            }else{
                 this.handleError( res.message.msg )
            }
        })
    }


    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Watch("List")
   ChangeList(newVal:Array<any>){
       HelpStore.SetHelpAllList( newVal )
   }

   @Watch("UpData",{ deep:true })
   ChangeUpData(newVal:AllHelpUpData){
       HelpStore.SetHelpAllUpData(newVal)
   }
}
